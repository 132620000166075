
/* eslint-disable */
import CloudFun, { Condition, defineComponent, Operator, ref } from '@cloudfun/core'
import Grid, { GridOptions } from '@/cloudfun/components/Grid.vue'
import { VxeFormProps } from 'vxe-table'
import SelectBox, { SelectBoxOptions } from "@/cloudfun/components/SelectBox.vue";
import formatDate from 'xe-utils/toDateString'
import { commafy } from "xe-utils";

export default defineComponent({
  components: {
    Grid,
    SelectBox,
  },
  setup () {
    const model = CloudFun.current?.model;
    const documentStatus = ref();
    const date = ref();
    const enums = model?.enums as any;
    const sdate = ref();
    const edate = ref();

    const grid = ref<any>({});
    var printColumns = [
      { field: 'Name' },
      { field: 'Description' }
    ]

    const gridOptions: GridOptions = {
      stripe: false,
      title: '文章點閱及下載次數統計管理',
      toolbarConfig: {
        custom: true,
        refresh: true
      },
      multiselect: false,
      canDelete: false,
      canUpdate: false,
      canCreate: false,
      canRead: false,
      printConfig: { sheetName: '文章點閱及下載次數統計管理清單', columns: printColumns, modes: ['current', 'selected', 'all'] },
      exportConfig: { filename: '文章點閱及下載次數統計管理清單', type: 'csv', types: ['html', 'csv'], mode: 'all', modes: ['current', 'selected', 'all'], columns: printColumns },
      columns: [
        { field: 'Title', title: '標題', showHeaderOverflow: true, showOverflow: true, minWidth: '30%' },
        { field: 'Author', title: '作者', showHeaderOverflow: true, showOverflow: true },
        { field: 'BulletinType', title: '類型', showHeaderOverflow: true, showOverflow: true },
        { field: 'Category', title: '類別', showHeaderOverflow: true, showOverflow: true },
        { field: 'Date', title: '日期', showHeaderOverflow: true, showOverflow: true, formatter: ({ cellValue }) => cellValue ? formatDate(new Date(cellValue), 'yyyy/MM/dd') : '' },
        { field: 'ViewCount', title: '檢視次數', showHeaderOverflow: true, showOverflow: true, align: 'right', formatter: ({ cellValue }) => `${commafy(cellValue)}` },
        { field: 'DownloadCount', title: '檔案下載次數', showHeaderOverflow: true, showOverflow: true, align: 'right', formatter: ({ cellValue }) => `${commafy(cellValue)}` },
        // { field: 'Status', title: '狀態', showHeaderOverflow: true, showOverflow: true },
      ],
      promises: {
        query: model ? (params: any) => {
          if (params?.sortings?.length === 0) {
            params.sortings.push({ column: 'CreatedTime', order: 1 });
          }
          if(sdate.value) {
            params.sdate = formatDate(sdate.value, 'yyyy-MM-dd')
          } else {
            params.sdate = null;
          }
          if(edate.value) {
            params.edate = formatDate(edate.value, 'yyyy-MM-dd')
          } else {
            params.edate = null;
          }
          // if(grid.keyword.value) {
          //   params.keyword = documentStatus.value
          // }
          console.log(params.date, params.keyword)
          return model.dispatch('report/queryBulletinStatistics', params)
        } : undefined,
        queryAll: model ? () => model.dispatch('report/queryBulletinStatistics') : undefined,
      },
      modalConfig: { width: "70%", showFooter: true },
      // treeConfig: { children: 'Children' }
    }

    const formOptions: VxeFormProps = {
      titleWidth: 120,
      titleAlign: 'right',
      items: [
        {
          field: 'BulletinType',
          title: '類型',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: true }, attrs: { type: 'text' } }
        },
        {
          field: 'Date',
          title: '日期',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: true }, attrs: { type: 'text' } }
        },
        {
          field: 'Title',
          title: '標題',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: true }, attrs: { type: 'text' } }
        },
        {
          field: 'Author',
          title: '作者',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: true }, attrs: { type: 'text' } }
        },
        {
          field: 'ViewCount',
          title: '檢視次數',
          span: 12,
          itemRender: { name: '$input', props: { type: 'number', placeholder: '請輸入數字', readonly: true } }
        },
        {
          field: 'DownloadCount',
          title: '檔案下載次數',
          span: 12,
          itemRender: { name: '$input', props: { type: 'number', placeholder: '請輸入數字', readonly: true } }
        },
      ],
      rules: {
      }
    }

    const s2ab = (s: any) => {
      var buf = new ArrayBuffer(s.length);
      var view = new Uint8Array(buf);
      for (var i=0; i!=s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
      return buf;
    }

    const exportExcel = async() => {
      const params = {} as any;
      if(sdate.value) {
        params.sdate = formatDate(sdate.value, 'yyyy-MM-dd')
      } else {
        params.sdate = null;
      }
      if(edate.value) {
        params.edate = formatDate(edate.value, 'yyyy-MM-dd')
      } else {
        params.edate = null;
      }
      try {
        var data = await model!.dispatch('report/queryBulletinStatistics', { keyword: grid.value.keyword, sdate: params.sdate, edate: params.edate, export: true });
        const file = new Blob([s2ab(atob(data))], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const fileURL = URL.createObjectURL(file);
        const link = document.createElement('a');
        link.href = fileURL;
        link.download = "文章點閱及下載次數統計.xlsx";
        link.click();
      } catch (error: any) {
        CloudFun.send("error", {
          subject: "執行失敗",
          content: error,
        });
      }
    }

    return {
      grid,
      gridOptions,
      formOptions,
      model,
      documentStatus,
      sdate,
      edate,
      exportExcel,
    }
  },
  methods: {
    hideBatchDropDown () {
      cash('#batch-dropdown').dropdown('hide')
    }
  }
})
