<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">文章點閱及下載次數統計</h2>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
        <div v-show="!grid.isEditing && grid.isRowSelected" id="batch-dropdown" class="dropdown ml-auto mr-2 sm:ml-0">
          <button class="dropdown-toggle button px-2 box bg-blue-500 text-white flex items-center">
            <FontAwesome icon="tasks" type="fas" class="w-4 h-4 mr-1" />
            群組操作
          </button>
          <div class="dropdown-box">
            <div class="dropdown-box__content box dark:bg-dark-1 p-2 w-32">
              <!-- <span
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="grid.removeSelectedRows"
              >
                <FontAwesome icon="trash" type="fas" class="w-4 h-4 mr-2" /> 刪除
              </span> -->
              <span
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="grid.onPrint({ mode: 'selected' })"
              >
                <FontAwesome icon="print" type="fas" class="w-4 h-4 mr-2" /> 列印
              </span>
              <span
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="grid.onExport({ type: 'csv', mode: 'selected' })"
              >
                <FontAwesome icon="file-csv" type="fas" class="w-4 h-4 mr-2" /> 匯出 CSV
              </span>
              <span
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="grid.onExport({ type: 'html', mode: 'selected' })"
              >
                <FontAwesome icon="file" type="fas" class="w-4 h-4 mr-2" /> 匯出 HTML
              </span>
            </div>
          </div>
        </div>
        <!-- <button v-show="!grid.isEditing" class="button text-white bg-theme-1 shadow-md mr-2 flex items-center" @click="grid.addNewRow">
          <FontAwesome icon="plus" type="fas" class="w-4 h-4 mr-1" />
          新增
        </button> -->
        <button type="button" class="button text-white bg-theme-1 shadow-md mr-2 flex items-center"
          @click="exportExcel">
          <FontAwesome icon="file-export" type="fas" class="w-4 h-4 mr-1" />
          匯出資料
        </button>
      </div>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">
      <div class="flex flex-col sm:flex-row sm:items-end xl:items-start">
        <div class="xl:flex sm:mr-auto">
          <div class="sm:flex items-center ml-1 sm:mr-2 mt-2 xl:mt-0">
            <vxe-input v-model="sdate" placeholder="起始日期" type="date" @change="grid.refresh" clearable></vxe-input>
          </div>
          <div class="sm:flex items-center ml-1 sm:mr-2 mt-2 xl:mt-0">
            <vxe-input v-model="edate" placeholder="結束日期" type="date" @change="grid.refresh" clearable></vxe-input>
          </div>
          <div class="sm:flex items-center ml-1 sm:mr-2 mt-2 xl:mt-0">
            <input type="search" class="input w-full sm:w-80 xxl:w-full mt-2 sm:mt-0 border" v-model.trim="grid.keyword" placeholder="請輸入文章點閱及下載次數統計" />
          </div>
          <div class="mt-2 xl:mt-0">
            <button type="button" class="button w-full sm:w-16 bg-theme-1 text-white" @click="grid.refresh">
              搜尋
            </button>
          </div>
        </div>
        <!-- <div class="flex mt-2 sm:mt-0">
          <button class="button w-1/2 sm:w-auto flex items-center border text-gray-700 dark:bg-dark-5 dark:text-gray-300" @click="grid.onPrint({ mode: 'curret' })">
            <FontAwesome icon="print" class="w-4 h-4 mr-2" /> 列印
          </button>
          <div class="dropdown w-1/2 sm:w-auto ml-2">
            <button class="dropdown-toggle button w-full sm:w-auto flex items-center border text-gray-700 dark:bg-dark-5 dark:text-gray-300">
              <FontAwesome icon="file-export" class="w-4 h-4 mr-2" /> 匯出
              <ChevronDownIcon class="w-4 h-4 ml-auto sm:ml-2" />
            </button>
            <div class="dropdown-box">
              <div class="dropdown-box__content box dark:bg-dark-1 p-2">
                <a
                  href="javascript:;"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                  @click="grid.onExport({ type: 'csv' })"
                >
                  <FontAwesome icon="file-csv" class="w-4 h-4 mr-2" /> CSV
                </a>
                <a
                  href="javascript:;"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                  @click="grid.onExport({ type: 'html' })"
                >
                  <FontAwesome icon="file" type="fas" class="w-4 h-4 mr-2" /> HTML
                </a>
              </div>
            </div>
          </div>
        </div> -->
      </div>
      <div class="overflow-x-auto scrollbar-hidden mt-2">
        <grid
          ref="grid"
          v-bind="gridOptions"
          @removeSelectedRows="hideBatchDropDown()"
          @modalResize="onGridModalResize"
        >
          <template #modal="{ row, submit, reset }">
            <vxe-form
              ref="form"
              v-bind="formOptions"
              :data="row"
              @reset="reset"
              @submit="submit"
            >
            </vxe-form>
          </template>
          <template #modal-footer>
            <vxe-button type="button" status="primary" content="關閉" @click="() => { grid.isModalPopup = false }"></vxe-button>
            <!-- <vxe-button type="submit" status="primary" content="確認" @click="$refs.form.validate((errMap) => { if (errMap === undefined) $refs.form.dispatchEvent('submit'); })"></vxe-button>
            <vxe-button type="reset" content="重置" @click="$refs.form.dispatchEvent('reset')"></vxe-button> -->
          </template>
          <template #filter-name="{ column, $panel }">
            <div v-for="(option, index) in column.filters" :key="index" class="flex item-center justify-center">
              <input type="checkbox"
                class="input input--switch border mt-4 ml-2"
                :checked="option.checked"
                @input="grid.changeFilterEvent(event, option, $panel, true)"
              />
              <div class="relative m-2">
                <div class="absolute rounded-l-md w-10 h-full flex items-center justify-center bg-gray-100 dark:bg-dark-1 dark:border-dark-4 border text-gray-600">
                  {{option.label}}
                </div>
                <input
                  v-model.trim="option.data"
                  :disabled="!option.checked"
                  type="text"
                  class="input pl-12 w-full border col-span-4"
                  placeholder="請輸入文字"
                  @input="grid.changeFilterEvent(event, option, $panel)"
                />
              </div>
            </div>
          </template>
        </grid>
      </div>
    </div>
    <!-- END: HTML Table Data -->
  </div>
</template>

<script lang="ts">
/* eslint-disable */
import CloudFun, { Condition, defineComponent, Operator, ref } from '@cloudfun/core'
import Grid, { GridOptions } from '@/cloudfun/components/Grid.vue'
import { VxeFormProps } from 'vxe-table'
import SelectBox, { SelectBoxOptions } from "@/cloudfun/components/SelectBox.vue";
import formatDate from 'xe-utils/toDateString'
import { commafy } from "xe-utils";

export default defineComponent({
  components: {
    Grid,
    SelectBox,
  },
  setup () {
    const model = CloudFun.current?.model;
    const documentStatus = ref();
    const date = ref();
    const enums = model?.enums as any;
    const sdate = ref();
    const edate = ref();

    const grid = ref<any>({});
    var printColumns = [
      { field: 'Name' },
      { field: 'Description' }
    ]

    const gridOptions: GridOptions = {
      stripe: false,
      title: '文章點閱及下載次數統計管理',
      toolbarConfig: {
        custom: true,
        refresh: true
      },
      multiselect: false,
      canDelete: false,
      canUpdate: false,
      canCreate: false,
      canRead: false,
      printConfig: { sheetName: '文章點閱及下載次數統計管理清單', columns: printColumns, modes: ['current', 'selected', 'all'] },
      exportConfig: { filename: '文章點閱及下載次數統計管理清單', type: 'csv', types: ['html', 'csv'], mode: 'all', modes: ['current', 'selected', 'all'], columns: printColumns },
      columns: [
        { field: 'Title', title: '標題', showHeaderOverflow: true, showOverflow: true, minWidth: '30%' },
        { field: 'Author', title: '作者', showHeaderOverflow: true, showOverflow: true },
        { field: 'BulletinType', title: '類型', showHeaderOverflow: true, showOverflow: true },
        { field: 'Category', title: '類別', showHeaderOverflow: true, showOverflow: true },
        { field: 'Date', title: '日期', showHeaderOverflow: true, showOverflow: true, formatter: ({ cellValue }) => cellValue ? formatDate(new Date(cellValue), 'yyyy/MM/dd') : '' },
        { field: 'ViewCount', title: '檢視次數', showHeaderOverflow: true, showOverflow: true, align: 'right', formatter: ({ cellValue }) => `${commafy(cellValue)}` },
        { field: 'DownloadCount', title: '檔案下載次數', showHeaderOverflow: true, showOverflow: true, align: 'right', formatter: ({ cellValue }) => `${commafy(cellValue)}` },
        // { field: 'Status', title: '狀態', showHeaderOverflow: true, showOverflow: true },
      ],
      promises: {
        query: model ? (params: any) => {
          if (params?.sortings?.length === 0) {
            params.sortings.push({ column: 'CreatedTime', order: 1 });
          }
          if(sdate.value) {
            params.sdate = formatDate(sdate.value, 'yyyy-MM-dd')
          } else {
            params.sdate = null;
          }
          if(edate.value) {
            params.edate = formatDate(edate.value, 'yyyy-MM-dd')
          } else {
            params.edate = null;
          }
          // if(grid.keyword.value) {
          //   params.keyword = documentStatus.value
          // }
          console.log(params.date, params.keyword)
          return model.dispatch('report/queryBulletinStatistics', params)
        } : undefined,
        queryAll: model ? () => model.dispatch('report/queryBulletinStatistics') : undefined,
      },
      modalConfig: { width: "70%", showFooter: true },
      // treeConfig: { children: 'Children' }
    }

    const formOptions: VxeFormProps = {
      titleWidth: 120,
      titleAlign: 'right',
      items: [
        {
          field: 'BulletinType',
          title: '類型',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: true }, attrs: { type: 'text' } }
        },
        {
          field: 'Date',
          title: '日期',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: true }, attrs: { type: 'text' } }
        },
        {
          field: 'Title',
          title: '標題',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: true }, attrs: { type: 'text' } }
        },
        {
          field: 'Author',
          title: '作者',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '請輸入文字', readonly: true }, attrs: { type: 'text' } }
        },
        {
          field: 'ViewCount',
          title: '檢視次數',
          span: 12,
          itemRender: { name: '$input', props: { type: 'number', placeholder: '請輸入數字', readonly: true } }
        },
        {
          field: 'DownloadCount',
          title: '檔案下載次數',
          span: 12,
          itemRender: { name: '$input', props: { type: 'number', placeholder: '請輸入數字', readonly: true } }
        },
      ],
      rules: {
      }
    }

    const s2ab = (s: any) => {
      var buf = new ArrayBuffer(s.length);
      var view = new Uint8Array(buf);
      for (var i=0; i!=s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
      return buf;
    }

    const exportExcel = async() => {
      const params = {} as any;
      if(sdate.value) {
        params.sdate = formatDate(sdate.value, 'yyyy-MM-dd')
      } else {
        params.sdate = null;
      }
      if(edate.value) {
        params.edate = formatDate(edate.value, 'yyyy-MM-dd')
      } else {
        params.edate = null;
      }
      try {
        var data = await model!.dispatch('report/queryBulletinStatistics', { keyword: grid.value.keyword, sdate: params.sdate, edate: params.edate, export: true });
        const file = new Blob([s2ab(atob(data))], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const fileURL = URL.createObjectURL(file);
        const link = document.createElement('a');
        link.href = fileURL;
        link.download = "文章點閱及下載次數統計.xlsx";
        link.click();
      } catch (error: any) {
        CloudFun.send("error", {
          subject: "執行失敗",
          content: error,
        });
      }
    }

    return {
      grid,
      gridOptions,
      formOptions,
      model,
      documentStatus,
      sdate,
      edate,
      exportExcel,
    }
  },
  methods: {
    hideBatchDropDown () {
      cash('#batch-dropdown').dropdown('hide')
    }
  }
})
</script>
<style scoped>
.custom-vue-selector :deep(.vue-tags-input.ti-disabled) {
  background-color: #f3f3f3;
}

.vxe-form--item.is--error .custom-vue-selector :deep(.vue-tags-input .ti-input) {
  border-color: #f56c6c !important;
}
</style>